import {
    BodyText, PageBlock, PrimaryText, TickLabels,
    HeaderBox, HeaderTitle, PrimaryButton, HeaderDescription, SecondaryText
} from "../../features/Atoms";
import Layout from "../Layout";
import { Grid, Box, Paper, CardMedia, Card, CardContent, Divider, Hidden } from "@material-ui/core";

import indexImg from "./../../images/Indexbackground.jpg";
import CertifiedTeacherBlock from "./Block/CertifiedTeacherBlock";
import indexBg from "./../../images/indexBg.jpg"
import whychooseicon5 from "./../../images/whychooseicon5.png"
import Teachers_page from "./../../images/Teachers_page.jpg"
import teacher_lap from "./../../images/ty-860x573.jpg"
import Teachers_page2 from "./../../images/Teachers_page2.jpg"
import blog3 from "./../../images/blog3-585x390.png"
import best_selection from "./../../images/best_selection.png"
import indepth_ass from "./../../images/indepth_ass.png"
import interviews from "./../../images/interviews.png"
import staff from "./../../images/staff.PNG"
import logos from "./../../images/logos.PNG"
import logoBack from "./../../images/logo-back.PNG"
import { Link } from "react-router-dom";
import teacher_pic1 from "./../../images/teacher_pic1.png"
import teacher_pic2 from "./../../images/teacher_pic2.jpg"
import teacher_pic3 from "./../../images/teacher_pic3.jpg"



export default function TeacherPage(props) {

    return (
        <Layout>
            <HeaderBox coverPic={staff} style={{ paddingTop: '35%', }} >
                <HeaderTitle className="marginGapBottom">
                    Learn from tech rockstars
                </HeaderTitle>
                <Hidden smDown>
                    <HeaderDescription className="marginGapBottom">
                        Software engineers from Carnegia Mellon. Industry disruptors from Caltech.
                        Digital artists from NYU. Our elite educators give your child a serious edge
                        for college and future careers.
                    </HeaderDescription>
                </Hidden>
                <Link component={PrimaryButton} to='/course'
                    className="marginGapBottom">Find Your Perfect Program
                </Link> &nbsp;&nbsp;
            </HeaderBox>
            <PageBlock className="highlight">
                <Grid item xs={12} md={9} style={{ position: 'relative', margin: 'auto' }}>
                    <Paper className="courseDescPaper" elevation={3}
                        style={{ margin: '-12% auto', }}
                    >
                        <SecondaryText style={{ fontSize: '20px' }} className="marginGapBottom">
                            The right instructor changes everything
                        </SecondaryText>
                        <BodyText style={{ lineHeight: '30px' }}>
                            Passion for tech. Unmatched experties. Personality that brings out the
                            absolute best in your child. With 20+ years of experience, we know their
                            no replacement for the best teacher. That's why we hire elite, all-adult
                            educators and recruit the same carefully-vetted talent sought by companies like
                            Google, EA, Tesla and Disney. They hire who we hire.
                        </BodyText>
                    </Paper>
                </Grid>
            </PageBlock>
            <br /><br /><br /><br />
            <CertifiedTeacherBlock />
            <PageBlock style={{ backgroundImage: `url(${indexBg})` }}>
                <Grid item xs={9} component={Box} style={{ margin: 'auto' }}>
                    <PrimaryText style={{ fontSize: '30px' }}>Our intensive hiring process</PrimaryText>
                    <br />
                    <BodyText>
                        We recruit our stuff from the same prestigious universities
                        where we host our programs (Caltech, CMU, NYU, and more). Our
                        quality assurance processes assure consistancy across all programs
                        worldwide. Our staff is our brand.
                    </BodyText>
                </Grid>
                <Grid item xs={12} >
                    <Box component={Grid} pt={5} pb={10} container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <Paper style={{ padding: '9%' }} elevation={4}>
                                <img src={interviews} style={{ width: '25%' }} />
                                <SecondaryText>Rigorous Interviews</SecondaryText>
                                <BodyText>
                                    Applicants undergo a meticulous interview process conducted
                                    by our team of 40 Regional Manager
                                </BodyText>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper style={{ padding: '9%', marginTop: '11%' }} elevation={4}>
                                <img src={indepth_ass} style={{ width: '25%' }} />
                                <SecondaryText>In-depth assesment</SecondaryText>
                                <BodyText>
                                    Each candidate is evaluated on their technical experties,
                                    passion for tech, teaching experience, and personality fit.
                                </BodyText>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Paper style={{ padding: '9%' }} elevation={4}>
                                <img src={best_selection} style={{ width: '25%' }} />
                                <SecondaryText>Selecting the best of the best</SecondaryText>
                                <BodyText>
                                    Out of thousands of applicants anually, only the very best go on
                                    to wear the iD Tech badge.
                                </BodyText>
                            </Paper>
                        </Grid>
                    </Box>
                </Grid>
                {/* <Grid xs={12}>
                    <SecondaryText>Do you have what it takes to wear the iD Tech Badge?</SecondaryText>
                    <PrimaryButton>Apply for the best job ever</PrimaryButton>
                </Grid> */}
            </PageBlock>
            <IntensiveTrainingBlock />
            <SmallGroupBlock />
            <PageBlock style={{ backgroundImage: `url(${logoBack})` }}>
                <Grid item xs={12} md={6}>
                    <img src={logos} style={{ width: '95%' }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box style={{ textAlign: 'left', }}>
                        <PrimaryText style={{ fontSize: '35px' }}>
                            They hire who we hire
                        </PrimaryText>
                        <br />
                        <BodyText>
                            Technical prowess. Inspirational leadership. Incredible personalities.
                            it's no surprise that iconic companies like Google, EA, Microsoft and
                            Disney hire the same talent we do. Our people possess the whole package
                            of skills that top employers seek.
                        </BodyText>
                    </Box>
                </Grid>
            </PageBlock>
            <BackedIndustryBlock />
            <PageBlock style={{ background: '#f6f7f7' }}>
                <Grid item xs={8} style={{ margin: 'auto' }}>
                    <PrimaryText style={{ fontSize: '30px' }}>
                        Now that you have met our staff, you're ready to get started!
                    </PrimaryText><br />
                    <SecondaryText>
                        Choose the perfect program for your child.
                    </SecondaryText>
                </Grid>
                <Grid item xs={12}>
                    <Box component={Grid} container spacing={5} pl={5} pr={5}>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    style={{
                                        paddingTop: '68%',
                                        backgroundPosition: 'top'
                                    }}
                                    image={teacher_pic3}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <SecondaryText>
                                        1-on-1 Private Lesson
                                    </SecondaryText>
                                    <Divider />
                                    <Box style={{ textAlign: 'left', padding: '7% 0%' }}>
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />

                                    </Box>
                                    <Link component={PrimaryButton}
                                        to="/computer-course">View Online Private Lessons</Link>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    style={{
                                        paddingTop: '68%',
                                        backgroundPosition: 'top'
                                    }}
                                    image={teacher_pic2}
                                />
                                <CardContent>
                                    <SecondaryText>
                                        Virtual Tech Camps
                                    </SecondaryText>
                                    <Divider />
                                    <Box style={{ textAlign: 'left', padding: '7% 0%' }}>
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />

                                    </Box>
                                    <Link component={PrimaryButton}
                                        to="/techcamp-course">View Online Private Lessons</Link>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    style={{
                                        paddingTop: '68%',
                                        backgroundPosition: 'top'
                                    }}
                                    image={teacher_pic1}
                                    title="Contemplative Reptile"
                                />
                                <CardContent>
                                    <SecondaryText>
                                        8 Week Course
                                    </SecondaryText>
                                    <Divider />
                                    <Box style={{ textAlign: 'left', padding: '7% 0%' }}>
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />
                                        <TickLabels
                                            label="Build up a tech skillset with computer courses starting at a young age." />

                                    </Box>
                                    <Link component={PrimaryButton} disabled>View Online Private Lessons</Link>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                </Grid>
            </PageBlock>
        </Layout>
    );
}

const IntensiveTrainingBlock = () => (
    <PageBlock>
        <Grid item xs={12} md={6}>
            <img src={Teachers_page} style={{ width: '95%' }} />
        </Grid>
        <Grid item xs={12} md={6}>
            <Box style={{ textAlign: 'left', }}>
                <PrimaryText style={{ fontSize: '35px' }}>
                    Intensive training makes all the difference
                </PrimaryText>
                <br />
                <BodyText>
                    Via workshops, collaborative activities, and indepth educational seassions, our
                    instructor hone the skills needed to bring our safe, fun and inclusive culture of
                    life. Wheather you choose an online or on-campus program, you can rest assured that
                    your child's instructor is thoroughly trained to teach and inspire.
                </BodyText>
            </Box>
        </Grid>
    </PageBlock>
);
const SmallGroupBlock = () => (
    <PageBlock>
        <Grid item xs={12} md={6}>
            <Box style={{ textAlign: 'left', }}>
                <PrimaryText style={{ fontSize: '35px' }}>
                    CyberTech Small group class Guarantee
                </PrimaryText>
                <br />
                <BodyText>
                    We understand the impact of individual attention. At
                    iD Tech, your child will never get lost in the suffle. With small classes
                    guaranteed (average of just 5 students per instructor), our staff is in tune
                    with your child's unique needs.
                </BodyText>
            </Box>
        </Grid>
        <Grid item xs={12} md={6}>
            <img src={teacher_lap} style={{ width: '85%' }} />
        </Grid>
    </PageBlock>
);

const BackedIndustryBlock = () => (
    <PageBlock>
        <Grid item xs={12} md={6}>
            <img src={Teachers_page2} style={{ width: '85%' }} />
        </Grid>
        <Grid item xs={12} md={6}>
            <Box style={{ textAlign: 'left', }}>
                <PrimaryText style={{ fontSize: '35px' }}>
                    Backed by best support in the industry
                </PrimaryText>
                <br />
                <BodyText className="marginGapBottom">
                    An instructor is only as good as their support system. our stuff backed by:
                </BodyText>
                <TickLabels
                    label="A full-time carriculum development team." />
                <TickLabels
                    label="A global team of Regional Managers." />
                <TickLabels
                    label="A culture of open communication and positive reinforcement" />
                <TickLabels
                    label="The best learning management system, iD game Plan" />
                <TickLabels
                    label="The latest hardware and software." />
            </Box>
        </Grid>
    </PageBlock>
);