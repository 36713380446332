import {
    BannerTextLink, PrimaryText, BodyText, PrimaryButtonOutlined
} from "../../../features/Atoms"
import {
    Grid, Box
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import discovery_Bg_small from "./../../../images/discovery_Bg_small.jpg"
import free_tutorial_bg from "./../../../images/free_1-1_tutorial_bg.jpg"
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const BannerBlockBlock = () => (
    <Grid container component={Box} p={1}>
        <Grid xs={12} sm={6} item disableGutters>
            <BannerTextLink coverPic={discovery_Bg_small} >
                <BodyText style={{ fontWeight: 400, color: 'white', fontSize: 14 }}>
                    YOU CAN TEST OUR COMPUTER COURSES LIVE!
                </BodyText>
                <PrimaryText className="marginGapBottom"
                    style={{ fontSize: '24px', color: 'white', fontWeight: 'bold' }}>
                    Claim your 30 Min&nbsp;
                    <span className="greenText">Free Discovery Lesson</span>
                </PrimaryText>
                <Link to="/coming-soon" component={PrimaryButtonOutlined} className="marginGapBottom">
                    SCHEDULE NOW
                </Link>
            </BannerTextLink>
        </Grid>
        <Grid xs={12} sm={6} item>
            <BannerTextLink coverPic={free_tutorial_bg}>
                <BodyText style={{ fontWeight: 400, color: 'white', fontSize: 14 }}>
                    YOU CAN TEST OUR COMPUTER COURSES LIVE!
                </BodyText>
                <PrimaryText className="marginGapBottom"
                    style={{ fontSize: '24px', color: 'white', fontWeight: 'bold' }}>
                    Claim your 30 Min&nbsp;
                    <b className="greenText">Free Discovery Lesson</b>
                </PrimaryText>
                <PrimaryButtonOutlined>SCHEDULE NOW</PrimaryButtonOutlined>
            </BannerTextLink>
        </Grid>
    </Grid>
);

export default BannerBlockBlock;