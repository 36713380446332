import "./../homepage.css"
import {
    PageBlock, PrimaryText, BodyText, SecondaryText
} from "../../../features/Atoms"
import {
    Grid, List, ListItem
} from "@material-ui/core";
import whychooseicon1 from "./../../../images/whychooseicon1.png"
import whychooseicon2 from "./../../../images/whychooseicon2.png"
import whychooseicon3 from "./../../../images/whychooseicon3.png"
import whychooseicon4 from "./../../../images/whychooseicon4.png"
import whychooseicon5 from "./../../../images/whychooseicon5.png"
import whychooseicon6 from "./../../../images/whychooseicon6.png"
import curvedpics from "./../../../images/curvedpics.png"


const WhyChooseBlock = () => (
    <PageBlock style={{ background: '#f2f2f2' }}>
        <Grid item xs={12}>
            <PrimaryText className="marginGapBottom" >
                Why students choose Cybertech&nbsp;
                <b className="greenText">to <br /> supercharge their growth</b>
            </PrimaryText>
            <BodyText className="marginGapBottom marginGapTop">
                Looking for the best computer courses or simply to boost academic results? Cyberteck
                Academy has you covered. Efficient tutors guide the way and increase chances for a
                career in IT or prepare students for the next step in school.
            </BodyText>
        </Grid>
        <Grid item xs={12} container style={{ textAlign: 'left', }}>
            <Grid item xs={12} md={8} component={List} container>
                <InfoCardIconView icon={whychooseicon1}
                    header="Schools and Parks & Rec Centers choose us"
                    body="Familiar with our services and results, institutions include our courses to round off traditional education."
                />
                <InfoCardIconView icon={whychooseicon2}
                    header="Expertise from top educators"
                    body="We chose the top to bring the best in teaching. Experience goes a long way in delivering results."
                />
                <InfoCardIconView icon={whychooseicon3}
                    header="Learn at your own pace"
                    body="All students are different. Our tutors know this and personalise their teaching methods to best fit the student and their needs."
                />
                <InfoCardIconView icon={whychooseicon4}
                    header="Innovators train here"
                    body="Growth comes from being creative and working hard. Students learn self management skills for building their best self."
                />
                <InfoCardIconView icon={whychooseicon5}
                    header="Tech skills in demand"
                    body="Our Tech Camps prepare you for top careers and make it fun. We build an atmosphere where students start to love learning."
                />
                <InfoCardIconView icon={whychooseicon6}
                    header="Virtual or at Location"
                    body="Best of both worlds: our courses and camps are available both online and at selected Schools and Parks & Rec Centers."
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <img src={curvedpics} style={{ width: '100%', margin: 'auto' }} />
            </Grid>
        </Grid>
    </PageBlock>
);

export default WhyChooseBlock;

const InfoCardIconView = (props) => (
    <ListItem alignItems="flex-start" style={{ paddingBottom: '6%' }}
        component={Grid} container item sm={6} disableGutters>
        <Grid item xs={3}>
            <img src={props.icon } style={{ width: '80%' }} />
        </Grid>
        <Grid item xs={9}>
            <SecondaryText style={{color: '#515151', fontSize:'18px'}}>{props.header}</SecondaryText>
            <BodyText>{props.body}</BodyText>
        </Grid>
    </ListItem>
);