import {
    PrimaryButton,
    PageBlock, PrimaryText, BodyText
} from "../../../features/Atoms"
import {
    Grid
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import teachers from "./../../../images/teachers.png"


const AmazingTeachersBlock = () => (
    <PageBlock>
        <Grid container xs={12} item style={{ textAlign: 'left' }}>
            <Grid item sm={6}>
                <BodyText style={{ fontWeight: 600 }}>
                    AMAZING TEACHERS
                </BodyText>
                <PrimaryText className="marginGapBottom" >
                    We're Here To&nbsp;
                    <b className="greenText">Transform</b>&nbsp;You!
                </PrimaryText>
                <BodyText className="marginGapBottom" >
                    Top teachers, the right approach and an open attitude to learning are the ingredients that promise results in school.
                    <br /><br />
                    CyberTeck’s best talent pool:<br />
                    <ul>
                        <li>Recruited from top tech universities like MIT or NYU</li>
                        <li>USA-based teachers selected after extensive background checks</li>
                    </ul>                    
                </BodyText>
            </Grid>
            <Grid item sm={6} style={{ textAlign: 'center' }}>
                <img src={teachers} style={{ width: '90%' }} />
            </Grid>
        </Grid>
    </PageBlock>
);
export default AmazingTeachersBlock;