
import {
    PageBlock, PrimaryText, BodyText, SecondaryText
} from "../../../features/Atoms"
import {
    Grid, Box
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import landing_bg3_half1 from "./../../../images/landing_bg3_half1.png"
import connect_pic from "./../../../images/connect.png"
import process1 from "./../../../images/process1.png"
import process2 from "./../../../images/process2.png"
import process3 from "./../../../images/process3.png"
import process4 from "./../../../images/process4.png"


const TheProcessBlock = (props) => (
    <PageBlock style={{
        backgroundImage: `url(${landing_bg3_half1})`
    }} >
        <Grid xs={12} item>
            <BodyText style={{ fontWeight: 600 }}>
                THE PROCESS
            </BodyText>
            <PrimaryText className="marginGapBottom" >
                How It&nbsp;
                <b className="greenText">Works</b>
            </PrimaryText>
            <BodyText className="marginGapBottom" >
                We organised a few easy steps for you to sign up and join our tech savvy group
            </BodyText>
        </Grid>
        <Grid container xs={12} item
            style={{
                background: `url(${connect_pic})`, backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat', backgroundPosition: 'center', paddingTop: '13%'
            }}>
            <Box xs={3} item component={Grid} style={{ marginTop: '-4%' }}>
                <img src={process2} style={{ width: '90%' }} className="marginGapBottom" />
                <SecondaryText>Register With Us</SecondaryText>
                <BodyText>Create an account with just a few clicks</BodyText>
            </Box>
            <Box xs={3} item component={Grid} style={{ marginTop: '0%' }}>
                <img src={process4} style={{ width: '90%' }} className="marginGapBottom" />
                <SecondaryText>Search Course</SecondaryText>
                <BodyText>Browse our Computer Courses, Tech Camps & Math Tutoring </BodyText>
            </Box>
            <Box xs={3} item component={Grid} style={{ marginTop: '-8%' }}>
                <img src={process3} style={{ width: '90%' }} className="marginGapBottom" />
                <SecondaryText>Enroll Course</SecondaryText>
                <BodyText>Choose your location @Schools or Park & Rec Centers or @Home Virtual</BodyText>
            </Box>
            <Box xs={3} item component={Grid} style={{ marginTop: '-15%' }}>
                <img src={process1} style={{ width: '90%' }} className="marginGapBottom" />
                <SecondaryText>Learn & Enjoy</SecondaryText>
                <BodyText>Meet your teacher and start your tech journey</BodyText>
            </Box>
        </Grid>

    </PageBlock>
);
export default TheProcessBlock;