import { Box, Grid, Link, Paper, Typography, Hidden, Container, Divider } from "@material-ui/core";
import bannerImg from './../../../images/online-classroom-47-2-540x482.png';
import {
    PrimaryButton, PrimaryText,
    CourseBlock,
    SecondaryText
} from "./../../../features/Atoms";

export default (props) => {
    return (
    <Grid item xs={12} style={{margin: '8em 0'}}>
    <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} lg={6}>
                <PrimaryText>CyberTeck Academy’s top choice for Schools and<br/> <span style={{background: '#73d56e', color: 'white'}}>Parks & Rec Centers</span></PrimaryText>
                <SecondaryText style={{margin: '2em 0'}}>To reach everyone’s needs, our Computer Courses will be available with a live instructor. We make sure our teachers are present at your location, both for In-Person courses and for Virtual settings.</SecondaryText>
                <PrimaryButton onClick={props?.onClick} rounded style={{fontSize: '22px', background: 'white', color: 'black', border: '1px solid #73d56e'}}>KNOW MORE</PrimaryButton>
            </Grid>
            <Grid item xs={12} lg={6}>
                <img src={bannerImg} className="basicImage" />
            </Grid>
        </Grid>
    </Container></Grid>)
}