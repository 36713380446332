import indexBg from './../../../images/indexBg.jpg';
import intro_img from './../../../images/intro_img.png';
import Teachers_page2 from './../../../images/Teachers_page2.jpg';
import staff from './../../../images/staff.PNG';
import { Box, Grid, Paper, Typography, Hidden } from "@material-ui/core";
import {
    BodyText,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryButton, PrimaryText, SecondaryText
} from "./../../../features/Atoms"
import { Link } from 'react-router-dom';

export default () => {
    const isMobile = (window.innerWidth < 768)
    if (isMobile) {
        return <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={1} />
            <Grid item xs={10} >
                <PrimaryText style={{ padding: 10, fontSize: 18, fontWeight: 400, textAlign: 'left' }}>
                    CyberTeck Academy partners up with Schools and Parks & Rec Centers to deliver tech education closer to home. We support our students’ academic path by adding tech knowledge and programming to their skillset.
                    The future is tech and parents everywhere value our computer courses. Our game design, video animation, robotics and engineering courses build essential knowledge and make kids love learning.
                </PrimaryText>
                <Link to={"/contactus"} className="defaultLink"><PrimaryButton style={{ padding: 10, marginTop: 10, width: '20ch' }} rounded>Contact Us</PrimaryButton></Link>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12}>
                <img src={intro_img} className="basicImage" style={{}}/>
            </Grid>
        </Grid>

    }
    return (<Grid container className="schoolIntroduction" style={{ backgroundImage: `url(${intro_img})`, backgroundRepeat: 'no-repeat', minHeight: '80vh', padding: '5% 10%', paddingBottom: 0, backgroundPosition: 'center', marginTop: 30 }}>
        <Grid item xs={12} >
            <Grid container direction="row"
                justifyContent="flex-start"
                style={{ minHeight: '500px' }}>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={6} lg={6} xl={5} style={{ marginTop: '-70px', marginLeft: '12%' }} >
                    <PrimaryText style={{ fontSize: 18, fontWeight: 400, textAlign: 'left' }}>
                        CyberTeck Academy partners up with Schools and Parks & Rec Centers to deliver tech education closer to home. We support our students’ academic path by adding tech knowledge and programming to their skillset.
                        The future is tech and parents everywhere value our computer courses. Our game design, video animation, robotics and engineering courses build essential knowledge and make kids love learning.
                    </PrimaryText>
                    <Link to={"/contactus"} className="defaultLink"><PrimaryButton style={{ padding: 10, marginTop: 10, width: '20ch' }} rounded>Contact Us</PrimaryButton></Link>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6} />

            </Grid>
        </Grid>
    </Grid>)
}