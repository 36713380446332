import {
    BodyText, PageBlock, PrimaryText, TickLabels,
    HeaderBox, HeaderTitle, PrimaryButton, HeaderDescription, SecondaryText, CourseBlock
} from "../../features/Atoms";
import Layout from "../Layout";
import { Grid, Box, Paper, CardMedia, Card, CardContent, Divider, Hidden, Typography } from "@material-ui/core";

import computer_courses_icon from "./../../images/computer-courses-icon.png"
import tech_camps_icon from "./../../images/tech-camps-icon.png"
import courses_at_school_location_icon from "./../../images/courses-at-school-location-icon.png"
import indexImg from "./../../images/Indexbackground.jpg";
import CertifiedTeacherBlock from "./Block/CertifiedTeacherBlock";
import indexBg from "./../../images/indexBg.jpg"
import whychooseicon5 from "./../../images/whychooseicon5.png"
import Teachers_page from "./../../images/Teachers_page.jpg"
import teacher_lap from "./../../images/ty-860x573.jpg"
import Teachers_page2 from "./../../images/Teachers_page2.jpg"
import blog3 from "./../../images/blog3-585x390.png"
import best_selection from "./../../images/best_selection.png"
import indepth_ass from "./../../images/indepth_ass.png"
import interviews from "./../../images/interviews.png"
import staff from "./../../images/staff.PNG"
import logos from "./../../images/logos.PNG"
import logoBack from "./../../images/logo-back.PNG"
import { Link } from "react-router-dom";
import { CourseTypeButton } from "../HomePage/Blocks/CourseTypesBlock";
import EliteTeachersBlock from "../CourseDetailsPage/Blocks/EliteTeachersBlock"
import TestimonialBlock from "../HomePage/Blocks/TestimonialBlock";
import onlineClassroom from "./../../images/online-classroom-47-2-540x482.png"
import tech_landing_bg from "./../../images/tech_landing_bg-860x486.jpg"
import student_bg from "./../../images/student_bg-380x335.jpg"
import techcamp_pic from "./../../images/techcamp_pic.png"
import tech_laptop_graph_pic from "./../../images/laptop-graph-left3-small-380x380.png"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAllCourse } from "../../DataStore/CourseReducer";
import Data from "../../Data";
import Api from "../../Api";
import { useAuthorize } from "../../features/Authentication/Authorize";




export default function TechCampCoursePage(props) {

    const isTeacher = useAuthorize('TEACHER');
    const isStudent = useAuthorize('STUDENT');
    const courseListStatus = useSelector(state => state.course.loadAllCourse)
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        if (!mounted) {
            dispatch(loadAllCourse({
                courseCategory: [Data.Config.CourseCategory.TECH_CAMP.id]
            }));
            setMounted(true)
        }
    }, [])

    const registerHandle = () => {
        document.getElementById("authButton")?.click()
    }

    return (
        <Layout>
            <HeaderBox coverPic={tech_landing_bg} style={{ paddingTop: '35%', }} >
                <HeaderTitle className="marginGapBottom">
                    Experience fun and learn with CyberTeck Academy Camps
                </HeaderTitle>
                <Hidden smDown>
                    <HeaderDescription className="marginGapBottom">
                        Put that school-free time to good use and get your child excited about tech.
                        Technology-focused courses to make your child part of a smart group of friends.
                    </HeaderDescription>
                </Hidden>

                {
                    !isStudent && !isTeacher &&
                    <Link rounded component={PrimaryButton}
                        onClick={registerHandle}>REGISTER</Link>
                }&nbsp;&nbsp;
            </HeaderBox>
            <PageBlock className="highlight">
                <Grid item xs={12} style={{ position: 'relative', margin: 'auto' }}>
                    <Paper className="courseDescPaper" elevation={3}
                        style={{ margin: '-9% auto', padding: '5%' }}>
                        <SecondaryText className="marginGapBottom" style={{ fontSize: '28px' }}>
                            Design your own game and video universe at&nbsp;
                            <b className="greenTextBackground"> Tech Camps</b>
                        </SecondaryText>
                        <BodyText>
                            Join CyberTeck during Spring Break and Summer time to offer your child
                            the amazing experience of camp. Courses focused on design, animation and
                            modelling in popular game universe like Minecraft, Roblox or Scratch.
                            We make sure he learns essential tech skills, you can relax knowing they have fun.
                        </BodyText>
                    </Paper>
                </Grid>
            </PageBlock>
            <PageBlock>
                <Grid item xs={12} md={6} style={{ margin: 'auto' }}>
                    <PrimaryText className="marginGapBottom">Why Choose Tech Camps</PrimaryText>
                    <BodyText>
                        Trust experienced teachers to combine learning and fun during Summer and Spring Break
                    </BodyText>
                </Grid>
                <Grid item xs={12}>
                    <Box component={Grid} container>
                        <Grid item xs={12} md={6}>
                            <img src={tech_laptop_graph_pic} style={{ width: '70%' }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box component={Grid} container style={{ textAlign: 'left' }}
                                spacing={5}>
                                <Grid item xs={12} md={6}>
                                    <SecondaryText>
                                        Long history in additional Education
                                    </SecondaryText>
                                    <BodyText>
                                        We know what makes students hungry for knowledge and how to
                                        motivate them. More than 10 years experience in developing
                                        tech abilities and building futures.
                                    </BodyText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SecondaryText>
                                        Dedicated Tech Teachers
                                    </SecondaryText>
                                    <BodyText>
                                        Selected from top tech experts, our educators awake
                                        students’ interest and build up know-how and creativity
                                        in the fascinating world of tech.
                                    </BodyText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SecondaryText>
                                        We make learning fun
                                    </SecondaryText>
                                    <BodyText>
                                        We deliver education through game design, video animations
                                        and modelling for students to build up tech skills and
                                        boost their confidence.
                                    </BodyText>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SecondaryText>
                                        Flexibility and adaptability
                                    </SecondaryText>
                                    <BodyText>
                                        To fit the new present, we offer Virtual and at Locations
                                        options. Check out our partnerships with Schools or Parks
                                        & Rec Centers or bring learning anywhere.
                                    </BodyText>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </PageBlock>
            <PageBlock style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                <Grid xs={12} md={6}>
                    <Box p={13} pb={0} pt={3}>
                        <PrimaryText className="marginGapBottom">
                            Pick your Camp and choose your ground
                        </PrimaryText>
                        <BodyText>
                            Choose the option that fits you best: Virtual from at home or at Location School
                            and Parks & Rec Centers
                        </BodyText>
                    </Box>
                    <Box component={Grid} container spacing={3} p={13} pb={0} pt={6}>
                        <Grid item md={6} sm={4} xs={6}>
                            <CourseTypeButton bgColor="#73d56e"
                                icon={computer_courses_icon}
                                label="Virtual Tech Camp" />
                        </Grid>
                        <Grid item md={6} sm={4} xs={6}>
                            <CourseTypeButton bgColor="#126d77"

                                icon={courses_at_school_location_icon}
                                label="At School Locations" />
                        </Grid>
                    </Box>
                </Grid>
                <Grid xs={12} md={6}>
                    <img src={student_bg} style={{ width: '70%' }} />
                </Grid>
            </PageBlock>
            <PageBlock>
                <Grid item xs={12} md={8} style={{ margin: 'auto' }}>
                    <PrimaryText className="marginGapBottom">
                        Tech Camps during Summer and Spring Break
                    </PrimaryText>
                    <BodyText>
                        Make your child’s free time exciting and useful. Browse our courses and choose a
                        favorite tech camp with fun animation and game design courses.
                    </BodyText>
                </Grid>
                <Grid item xs={12}>
                </Grid>{
                    courseListStatus.status === 'FULFILLED' &&
                    courseListStatus.data &&
                    Object.values(Data.Config.Grade).map(grade =>
                        <>
                            <Grid xs={12} item disableGutter>
                                <SecondaryText className="greenText" style={{ fontSize: '28px' }}>
                                    Grade {grade.displayName}
                                </SecondaryText>
                            </Grid>
                            <Grid xs={12} item>
                                <Box container spacing={5} component={Grid} style={{ padding: '0px 7%' }}>
                                    {
                                        courseListStatus.data
                                            .filter(course => course.grade === grade.id)
                                            .slice(0, 6)
                                            .map(item =>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <CourseBlock
                                                        to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                                        label={item.title}
                                                        grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                                        courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                                        price={item.price}
                                                        mrpPrice={item.mrpPrice}
                                                        coverPic={item.coverImage && Api.Media.getThumb(item.coverImage)}
                                                    />
                                                </Grid>
                                            )
                                    }
                                </Box>
                            </Grid>
                        </>
                    )
                }
                <Grid xs={12} item>
                    <Link to="/courses" component={PrimaryButton}>View All Courses & Plan</Link>
                </Grid>
            </PageBlock>
            <TestimonialBlock />
            <EliteTeachersBlock />
            <PageBlock>
                <Grid item xs={12} md={6}>
                    <img src={onlineClassroom} style={{ width: '80%' }} />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: '5%' }}>
                    <PrimaryText style={{ fontSize: '35px' }}>
                        Tech Camps at Parks & Rec Centers and School Locations Near You
                    </PrimaryText>
                    <br /><br />
                    <BodyText>
                        CyberTeck Academy partners with Parks & Rec Centers and Schools to make sure students
                        complement their tech education. <br />
                        We make it easy and accessible for your kid to experience fun Summer and Spring Break
                        Camps. Check locations available and choose one that is closer to you. Go for Virtual or
                        In-Person and start attending at partner locations near you.
                    </BodyText>
                </Grid>
            </PageBlock>
            <PageBlock style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
                    <PrimaryText className="marginGapBottom marginGapTop">
                        Why go for our innovative Tech Camps
                    </PrimaryText>
                    <BodyText className="marginGapBottom">
                        We know how to light up the imagination of young minds and train essential
                        skills useful for any future tech savvy.
                    </BodyText>
                    <Box pt={3}>
                        <SecondaryText>
                            1. Comprehensive tech curricula
                        </SecondaryText>
                        <BodyText>
                            Design own projects and mods and dive into the world of gaming and animation
                            in everything from Minecraft and Scratch to Python and Roblox.
                        </BodyText>
                    </Box>
                    <Box pt={3}>
                        <SecondaryText>
                            2. Organize your schedule and your time
                        </SecondaryText>
                        <BodyText>
                            School free weeks can be fun and useful. You decide when and how often your
                            child joins their friends at fun tech camp.
                        </BodyText>
                    </Box>
                    <Box pt={3}>
                        <SecondaryText>
                            3. Choose from our partners schools
                        </SecondaryText>
                        <BodyText>
                            Choose from our available locations to experience camp time with friends
                            at Schools and Parks & Rec Centers, with Virtual or In-Person options.
                        </BodyText>
                    </Box>
                    <Box pt={3}>
                        <SecondaryText>
                            4. Go virtual for ultimate mobility
                        </SecondaryText>
                        <BodyText>
                            Develop critical thinking abilities at your own pace, in your own time, at home
                            with Virtual Tech Camp options.
                        </BodyText>
                    </Box>
                    <Box pt={3}>
                        <SecondaryText>
                            5. Spring Break and Summer courses to boost school studies
                        </SecondaryText>
                        <BodyText>
                            Take advantage of Spring and Summer breaks to bring your child two
                            steps ahead in school. They develop problem solving abilities and critical
                            thinking and make up for the past year in tech education.
                        </BodyText>
                    </Box>
                    <Box pt={3}>
                        <SecondaryText>
                            6. Share the camp experience with friends
                        </SecondaryText>
                        <BodyText>
                            The best part about learning tech and designing your own project is getting
                            to share it with friends. Challenge each other and advance together.
                        </BodyText>
                    </Box>
                    {
                        !isStudent && !isTeacher &&
                        <Link rounded component={PrimaryButton}
                            onClick={registerHandle}>REGISTER</Link>
                    }
                </Grid>
                <Grid item xs={12} md={6} style={{ margin: 'auto' }}>
                    <img src={techcamp_pic} style={{ width: '80%' }} />
                </Grid>
            </PageBlock>
        </Layout>
    );
}
