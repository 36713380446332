import staff from './../../../images/staff.PNG';
import tick_icon from "./../../../images/tick_icon.png";
import teachers_img from './../../../images/teachers.png';
import curve_laptop from './../../../images/curve_laptop.png';
import { Container, Box, Grid, Link, Paper, Typography, Hidden, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import {
    BodyText,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryButton, PrimaryText, SecondaryText
} from "./../../../features/Atoms";
import AddCircleIcon from '@material-ui/icons/AddCircle';
export default () => {
    return (
        <Grid container style={{ padding: '5% 10%', marginTop: '10%', backgroundImage: `url(${curve_laptop})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
            <Container maxWidth="xl" >
                <Grid container style={{minHeight: '150vh'}}>
                    <Grid item sm={8} xs={12}>
                        <PrimaryText>Additional education <span style={{ background: '#73d56e', color: 'white' }}>from A to Z</span></PrimaryText>
                        <SecondaryText style={{ color: '#106a75', fontSize: 18 }}>We supply the locations with both equipment and software, along with the necessary set-up. Everything prepared in advance to start learning right away.</SecondaryText>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <QuestionBlock question="Opportunities for all students, no matter the background" answer="Our courses build skills for the future and everyone is entitled to access education. That is why we offer scholarships to qualified underprivileged students and create equal chances for all." />
                        <QuestionBlock question="Selection of courses that teach in-demand tech skills" answer="We chose courses that are proven to build students’ skills for tomorrow. Classes with projects that cover programming like Python, robotics and drone engineering, 3D printing or game design and video animation." />
                        <QuestionBlock question="Top Tech Experts guide through the world of tech" answer="Qualified teachers selected from top tech universities make sure knowledge is built at the right pace and made easy for all to understand." />
                        <QuestionBlock question="You can bring us to your Location" answer="Besides existing collaborators, CyberTeck Academy teams up with more: Private Schools, Churches, Home School Groups, Sport Leagues or Parks & Rec Centers to meet your needs. Recommend us and we’ll make it happen." />
                        <QuestionBlock question="We believe in your children" answer="We know the future will require programming abilities. We want to bring our contribution to schools’ efforts in delivering a useful education for the world of tomorrow." />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 30 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item sm={4} lg={3}/>
                                    <Grid item sm={4} lg={6} xs={12} style={{marginTop: '15vh'}}>
                                        <SecondaryText style={{ textAlign: 'center', color: 'gray' }}>Our Teacher</SecondaryText>
                                        <PrimaryText style={{ textAlign: 'center' }}>Cyberteck Certified</PrimaryText>
                                        <PrimaryText style={{ textAlign: 'center' }}><span style={{ backgroundColor: '#73d56e', textAlign: 'center', color: 'white' }}>Instructors</span></PrimaryText>
                                    </Grid>
                                    <Grid item sm={4} lg={3}/>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} style={{marginTop: '10vh'}}>
                                <PrimaryText style={{ margin: '20px 0', fontSize: 30 }}>Dedicated teachers inspire young minds <br /> and build a creative hub</PrimaryText>
                                <SecondaryText style={{ margin: '20px 0', fontWeight: 400 }}>
                                First hand experience in virtual and in-person teaching makes them efficient,able to achieve fast results  and keep students engaged.
                                </SecondaryText>
                                <br />
                                <SecondaryText style={{ margin: '20px 0' }}>Why trust our instructors?</SecondaryText>
                                <br />

                                <TickLabels label={"Recruited from top tech universities like MIT or NYU"} />
                                <TickLabels label={"USA-based teachers selected after extensive background checks"} />
                                <TickLabels label={"Deliver results with proven teaching methods for all students"} />
                                <TickLabels label={"Know how to connect to students and develop their creative curiosity"} />
                            </Grid>
                            <Grid item sm={6} xs={12} style={{ minHeight: 500 }}>
                                <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: 500 }}>
                                    <img className="basicImage" src={teachers_img} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    )
}

const TickLabels = (props) => (
    <Typography
        variant="body1"
        {...props}
        className="bodyText"
        component="p"
        style={{ display: "flex", marginBottom: 15, marginTop: 10, ...props.style }}
    >
        <img
            src={tick_icon}
            style={{ width: "9%", marginRight: 10, objectFit: "contain" }}
        />
        <BodyText style={{ fontSize: "16px", lineHeight: "18px" }}>
            {props.label}
        </BodyText>
    </Typography>
);

export const QuestionBlock = (props) => (
    <Accordion style={{ margin: '15px 0', backgroundColor: '#72D46F', color: 'white', borderRadius: 5 }}>
        <AccordionSummary
            expandIcon={<AddCircleIcon style={{ color: 'white' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{props.question}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: 'white', color: 'black' }}>
            <BodyText>
                {props.answer}
            </BodyText>
        </AccordionDetails>
    </Accordion>
);