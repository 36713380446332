import Layout from "../Layout";
import {
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryButton, PrimaryText, SecondaryText
} from "./../../features/Atoms"
import { Box, Grid, Link, Paper, Typography, Hidden, Container } from "@material-ui/core";
import coverImg from "./../../images/schools_bg.jpg";
import { useEffect, useState } from "react";
import IntroductionSection from './blocks/introduction';
import SchoolDescription from "./blocks/schoolDescription";
import ProgramInfo from "./blocks/programInfo";
import Testimonial from './blocks/testtimonial';
import FactsSection from "./blocks/factsSection";
import CoursesSection from "./blocks/courses";
import SearchSchools from './blocks/search_school';
import CoursesPark from './blocks/courses_prk_rec';
import ContactModal from './../../features/ContactModal';
import { TabularSchoolList } from './../SchoolListingPage/schoolListingPage';
import { scroller } from "react-scroll";
export default () => {
    const [contactModalStatus, setContactModalStatus] = useState(false);
    const scrollToSection = () => {
        scroller.scrollTo("CourseSearchArea", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        });
      };
    return (
        <Layout className="detailsBlock">
            <HeaderBox className="schoolPageHeader"
                style={{ paddingTop: '40%' }}
                coverPic={coverImg}>
                <HeaderTitle>
                    Partnering <span style={{ background: '#73d56e', color: 'white' }}>with schools</span> to shape young<br /> minds  into future tech savvys
                </HeaderTitle>
                {/* <Hidden smDown> */}
                <PrimaryButton onClick={scrollToSection} style={{ padding: 10, fontSize: '1rem', width: "20ch", fontWeight: 500 }} rounded>Choose Location</PrimaryButton>
                {/* </Hidden> */}
            </HeaderBox>
            <ContactModal open={contactModalStatus} handleClose={() => setContactModalStatus(false)} />
            <IntroductionSection />
            <SchoolDescription />
            <CoursesSection />
            <ProgramInfo />
            <SearchSchools className="CourseSearchArea"/>
            <Container style={{ marginTop: 30 }}>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid item xs={8} style={{margin: '20px 0', marginTop: '10vh'}}>
                        <PrimaryText style={{textAlign: 'center'}}>Our Customer List</PrimaryText>
                    </Grid>
                </Grid>
                <TabularSchoolList />
            </Container>
            <PageBlock>
                <Box id="map"></Box>
            </PageBlock>
            <Testimonial />
            <CoursesPark onClick={() => setContactModalStatus(true)} />
            <FactsSection />
        </Layout>
    )
}