import React from "react";



const ThirdPane = () => (

    <div className="uk-container">
    <div className="uk-width-1-1 uk-padding@s">
        <h3  className="header1 withPaddingLarge" uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">Why parents choose CyberTeck Academy to empower their children</h3>
    </div>

</div>
    
)



export  default  ThirdPane