import { Box, Grid, Link, Paper, Typography, Hidden, Container } from "@material-ui/core";
import {
    BodyText,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryButton, PrimaryText, SecondaryText
} from "./../../../features/Atoms";
import curvedBg from './../../../images/curvedBg.jpg';
import computer_courses_icon from './../../../images/computer-courses-icon.png';


import curated_online_courses from './../../../images/curated_online_courses.png';
import dedicated_teachers from './../../../images/Dedicated_teachers.png';
import experience from './../../../images/experience.png';
import flexibility_affordability from './../../../images/flexibility_affordability.png'

export default () => {
    return (
        <Grid container style={{ marginTop:30, padding: '5% 10%', paddingBottom:0, backgroundImage: `url(${curvedBg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <Grid item xs={12}>
                <DescriptionContent />
            </Grid>
        </Grid>
    )
}



const DescriptionContent = () => {
    return (
        <>
            <Grid container className="schoolDescriptionBlock">
                <Grid item xs={12} style={{marginBottom: 0}}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <PrimaryText>Why Trust <span style={{ background: '#73d56e', color: 'white', padding: '1px 4px' }}>CyberTeck</span><br />
                                at School Location <br />Program
                            </PrimaryText>
                            <br/>
                            <SecondaryText style={{ marginTop: 15 }}>
                            To enhance results and open new doors, we are constantly adapting our selection of courses to fit today’s accelerated tech advance.
                            </SecondaryText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <HighlightBlocks body="Tech expertise and a passion for teaching makes our educators essential to preparing the next generation of tech savvys." heading="Dedicated Tech Teachers." icon={dedicated_teachers} />
                        <HighlightBlocks space body="CyberTeck Academy is long active in the education sector with a proven track record in delivering knowledge and building skills for the future." heading="Over 10 Years Experience" icon={experience} />
                        <HighlightBlocks body="Carefully chosen courses, specifically address age appropriate skill development. Best part: for students it’s a lot of fun." heading="Curated courses that deliver" icon={curated_online_courses} />
                        <HighlightBlocks space body="Every child should have access to receiving/  essential programming skills. Our rates and scheduling options reflect this." heading="Flexibility and affordability" icon={flexibility_affordability} />
                    </Grid>
                    </Container>
                </Grid>
            </Grid>
        </>
    )
}

const HighlightBlocks = props => {
    return (
        <Grid item sm={6} md={3} style={{ padding: 20, marginTop: props?.space ? '10em' : 0 }} className="schoolDescHighlightblocks">
            <Block {...props} />
        </Grid>
    )
}
const Block = props => {
    return <Box style={{backgroundColor: '#73d56e', padding: 20, borderRadius: 20}}>
        <Grid item xs={12}>
            <img className="basicImage" src={props?.icon} height="70" />
        </Grid>
        <Grid item xs={12}>
            <p style={{ color: 'white', fontSize: 17, textAlign: 'center', fontWeight: 600 }}>{props?.heading}</p>
        </Grid>
        <Grid item xs={12}>
            <p style={{ color: 'white', fontSize: 17, textAlign: 'center' }}>{props?.body}</p>
        </Grid>
    </Box>
}