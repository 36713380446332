import indexBg from './../../../images/indexBg.jpg';
import Teachers_page2 from './../../../images/Teachers_page2.jpg';
import stats_bg from './../../../images/stats_bg.jpg';
import f1 from './../../../images/f1.png';
import f2 from './../../../images/f2.png';
import f3 from './../../../images/f3.png';

import staff from './../../../images/staff.PNG';
import { Box, Grid, Link, Paper, Typography, Hidden, Container } from "@material-ui/core";
import {
    BodyText,
    HeaderBox, HeaderDescription, HeaderTitle, PageBlock, PrimaryButton, PrimaryText, SecondaryText
} from "./../../../features/Atoms"

export default () => {
    return (
        <Grid container style={{ marginTop: 30, padding: '5% 10%', backgroundImage: `url(${stats_bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
            <Grid item xs={12} style={{ marginTop: 30 }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <PrimaryText style={{ textAlign: 'center' }}>The numbers behind the <span style={{ color: '#73d56e' }}>*FACTS*</span></PrimaryText>
                        <SecondaryText style={{ textAlign: 'center', color: 'gray' }}>See here Stats and Facts about the benefits of Computer Courses for kids</SecondaryText>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '5em' }}>
                <Container>
                    <Grid container style={{ paddinTop: 20 }}>
                        <Grid item xs={12} md={4}>
                            <FactsBlock img={f1} quote="97% of teachers believe tech skills are a must in core education" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FactsBlock img={f2} quote="9 out of 10 cybertecK alumni go on to study STEM in college" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FactsBlock img={f3} quote="9 out of 10 increase in academic results when gamification is paired with study" />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    )
}

const FactsBlock = props => {
    return <Grid container style={{ padding: 20 }}>
        <Grid item xs={12}>
            <img src={props?.img} className="basicImage" style={{maxHeight: 300}}/>
        </Grid>
        <Grid item xs={12}>
            <SecondaryText style={{ textAlign: 'center' }}>{props?.quote}</SecondaryText>
        </Grid>
    </Grid>
}
