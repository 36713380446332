import React, { useState } from "react";
import { Grid, Button, Paper, Hidden, Divider, Drawer, ListItem, List, ListItemText, IconButton } from "@material-ui/core";
import mainLogo from "./../../../images/logo_main.png";
import Authentication from "./../../../features/Authentication";
import { Link } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function HeaderBar(props) {
  const [trasparent, setTrasparent] = useState(true);
  window.addEventListener("scroll", (e) => {
    setTrasparent(window.scrollY === 0);
  });
  const [openDrawer, setOpendrawer] = useState(false);
  return (
    <Paper
      component={Grid}
      container
      className="headerBar"
      elevation={1}
      square
      style={{
        background:
          !props.fillHeader && trasparent ? "transparent" : "rgba(0,0,0,0.93)",
      }}
    >
      <Grid item md={3} xs={6} className="logoContainer" component={Link}
        to='/'>
        <img
          src={mainLogo}
          alt="LOGO"
          style={{ width: !props.fillHeader && trasparent ? "75%" : "40%" }}
        />
      </Grid>
      <Hidden smDown>
        <Grid item md={7} className="navMenu">
          <Link to="/computer-course" className="navLink defaultLink" size="large" component={Button}>
            Computer Courses
          </Link>
          <Link to="/techcamp-course" className="navLink defaultLink" size="large" component={Button}>
            Tech Camps
          </Link>
          <Link to="/schools" className="navLink defaultLink" size="large" component={Button}>
            Courses At Schools
          </Link>
          <Link to="/teacher" className="navLink defaultLink" size="large" component={Button}>
            Our Teachers
          </Link>
          <Link to="/blog" className="navLink defaultLink" size="large" component={Button}>
            Blog
          </Link>
          <Link to="/contactus" className="navLink defaultLink" size="large" component={Button}>
            Contact Us
          </Link>
          <Link to="/about-us" className="navLink defaultLink" size="large" component={Button}>
            About Us
          </Link>
        </Grid>
      </Hidden>
      <Grid
        item
        md={2}
        xs={6}
        className="navMenu"
        style={{ justifyContent: "flex-end", paddingRight: "5%" }}
      >
        <Authentication />
        <Hidden mdUp>
          <MenuIcon style={{ color: 'white' }} onClick={() => setOpendrawer(true)} />
          <HamburgerMenuForMobile open={openDrawer} handleClose={() => setOpendrawer(false)} />
        </Hidden>
      </Grid>
    </Paper>
  );
}

const HamburgerMenuForMobile = props => {
  const drawerWidth = window.innerWidth < 768 ? "85%" : "30%";
  const isMobile = (window.innerWidth < 768);

  const useStyles = makeStyles((theme) => ({
    backDrop: {
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const ListItemForNavbar = (props) => {
    return <ListItem button>
      <Link to={props?.to} style={{ padding: 20 }} className="navLink defaultLink" size="large">
        {props.label}
      </Link>
    </ListItem>
  }
  return (
    <Drawer open={props?.open} onClose={props?.handleClose} className={classes.drawer}
      transitionDuration={{ enter: 300, exit: 700 }}
      variant="temporary"
      anchor="right" classes={{
        paper: classes.drawerPaper,
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={props?.handleClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <List>
      <ListItemForNavbar to="/computer-course" label="Computer Course"/>
      <ListItemForNavbar to="/about-us" label="About Us"/>
      <ListItemForNavbar to="/techcamp-course" label="Tech Camp"/>
      <ListItemForNavbar to="/schools" label="Courses At Schools"/>
      <ListItemForNavbar to="/teacher" label="Our Teachers"/>
      <ListItemForNavbar to="/blog" label="Blogs"/>
      <ListItemForNavbar to="/contactus" label="Contact Us"/>
      <ListItemForNavbar to="/about-us" label="About Us"/>
      </List>
    </Drawer>

  )

}

export default HeaderBar;
