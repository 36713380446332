import "./../homepage.css"
import {
    PageBlock, PrimaryText, BodyText, PrimaryButtonOutlined
} from "../../../features/Atoms"
import {
    Grid
} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import intro_pics from "./../../../images/intro_pics.png"


const TechWideWorldBlock = () => (
    <PageBlock style={{ background: '#f6f7f7' }}
        gridStyle={{ paddingTop: 0 }} >
        <Grid container xs={12} item style={{ textAlign: 'left' }}>
            <Grid item xs={12} sm={6}>
                <BodyText style={{ fontWeight: 600 }}>
                    Everything in CyberTeck
                </BodyText>
                <PrimaryText className="marginGapBottom" >
                    Learn about<br/>
                    <b className="greenText">CyberTeck</b>&nbsp;Academy
                </PrimaryText>
                <BodyText className="marginGapBottom" >
                    Cyberteck Academy offers cutting-edge Computer and Design courses for
                    video games and Tutoring Services for young students.<br/><br/>
                    Our tutoring services in the form of Learning Pods. 1-on-1 and group
                    Tutoring service in Math & English target K-8 students.We designed our
                    Learing Podwith you in mindhomework assistance, flexible in terms of
                    scheduling and perticipants. We provide undivided attention and a
                    collablrative medium with other classmates.
                </BodyText>
                {/* <PrimaryButtonOutlined rounded>Know More</PrimaryButtonOutlined> */}
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                <img src={intro_pics} style={{ width: '90%' }} />
            </Grid>
        </Grid>
    </PageBlock>
);

export default TechWideWorldBlock;