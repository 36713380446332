import {
    BodyText, PageBlock, PrimaryText, TickLabels,
    HeaderBox, HeaderTitle, PrimaryButton, HeaderDescription, SecondaryText, CourseBlock
} from "../../features/Atoms";
import Layout from "../Layout";
import { Grid, Box, Paper, CardMedia, Card, CardContent, Divider, Hidden, Typography } from "@material-ui/core";

import computer_courses_icon from "./../../images/computer-courses-icon.png"
import tech_camps_icon from "./../../images/tech-camps-icon.png"
import courses_at_school_location_icon from "./../../images/courses-at-school-location-icon.png"
import indexImg from "./../../images/Indexbackground.jpg";
import CertifiedTeacherBlock from "./Block/CertifiedTeacherBlock";
import indexBg from "./../../images/indexBg.jpg"
import whychooseicon5 from "./../../images/whychooseicon5.png"
import Teachers_page from "./../../images/Teachers_page.jpg"
import teacher_lap from "./../../images/ty-860x573.jpg"
import Teachers_page2 from "./../../images/Teachers_page2.jpg"
import blog3 from "./../../images/blog3-585x390.png"
import best_selection from "./../../images/best_selection.png"
import indepth_ass from "./../../images/indepth_ass.png"
import interviews from "./../../images/interviews.png"
import staff from "./../../images/discovery_Bg_small.jpg"
import logos from "./../../images/logos.PNG"
import logoBack from "./../../images/logo-back.PNG"
import { Link } from "react-router-dom";
import { CourseTypeButton } from "../HomePage/Blocks/CourseTypesBlock";
import EliteTeachersBlock from "../CourseDetailsPage/Blocks/EliteTeachersBlock"
import TestimonialBlock from "../HomePage/Blocks/TestimonialBlock";
import onlineClassroom from "./../../images/online-classroom-47-2-540x482.png"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAllCourse } from "../../DataStore/CourseReducer";
import Api from "../../Api";
import Data from "../../Data";
import cs_video2 from "./../../images/cs_video2.png"
import badge1 from "./../../images/badge1.png"
import badge2 from "./../../images/badge2.png"
import badge3 from "./../../images/badge3.png"


export default function ComputerCoursePage(props) {
    const courseListStatus = useSelector(state => state.course.loadAllCourse)
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        if (!mounted) {
            dispatch(loadAllCourse({
                courseCategory: [Data.Config.CourseCategory.COMPUTER_COURSE.id]
            }));
            setMounted(true)
        }
    }, [])

    return (
        <Layout>
            <HeaderBox coverPic={staff} style={{ paddingTop: '35%', }} >
                <HeaderTitle className="marginGapBottom">
                    Experience more than playing the game. Design the game.
                </HeaderTitle>
                <Hidden smDown>
                    <HeaderDescription className="marginGapBottom">
                        Open your child’s mind to endless possibilities. We enhance their tech skills with
                        exciting game design, coding or 3D animation computer courses. Our expert teachers make sure it’s all fun.
                        Available all year round.
                    </HeaderDescription>
                </Hidden>
                <Link component={PrimaryButton} to='/coming-soon'
                    className="marginGapBottom">Free 30 MINS LESSONS
                </Link> &nbsp;&nbsp;
            </HeaderBox>
            <PageBlock className="highlight">
                <Grid item xs={12} style={{ position: 'relative', margin: 'auto' }}>
                    <Paper className="courseDescPaper" elevation={3}
                        style={{ margin: '-9% auto', padding: '5%' }}
                    >
                        <SecondaryText className="marginGapBottom">
                            CyberTeck Academy brings you immersive computer courses to boost your
                            child’s tech skills. A bubbling creative hub to advance abilities and
                            share experiences with friends.
                        </SecondaryText>
                        <Box component={Grid} container spacing={2} pt={2}
                            style={{ textAlign: 'left' }}>
                            <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                                <Box pr={1}>
                                    <img src={badge1} style={{ width: '55px' }} />
                                </Box>
                                <Box>
                                    <SecondaryText style={{ fontSize: '13px' }}>
                                        Personalized Learning
                                    </SecondaryText>
                                    <BodyText style={{ fontSize: '13px' }}>
                                        Tailored learning program for each student,
                                        adapted courses and scheduling options
                                    </BodyText>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                                <Box pr={1}>
                                    <img src={badge2} style={{ width: '55px' }} />
                                </Box>
                                <Box>
                                    <SecondaryText style={{ fontSize: '13px' }}>
                                        Qualified Teachers
                                    </SecondaryText>
                                    <BodyText style={{ fontSize: '13px' }}>
                                        Expertise and passion to empower young minds,
                                        develop their creativity and shape futures
                                    </BodyText>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                                <Box pr={1}>
                                    <img src={badge3} style={{ width: '55px' }} />
                                </Box>
                                <Box>
                                    <SecondaryText style={{ fontSize: '13px' }}>
                                        Over 10 years Experience</SecondaryText>
                                    <BodyText style={{ fontSize: '13px' }}>
                                        A long history in maximizing students’ potential
                                        and helping them develop their natural talents
                                    </BodyText>
                                </Box>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={9} style={{ margin: 'auto', paddingTop: '13%' }}>
                    <PrimaryText>Experiences to choose from</PrimaryText>
                    <Box component={Grid} container spacing={7} pt={7}>
                        <Grid item lg={4} md={6} sm={4} xs={6}>
                            <CourseTypeButton bgColor="#73d56e"
                                icon={computer_courses_icon}
                                targetLink="/computer-course"
                                desc="Tailored program adapted to student’s knowledge and pace. Fast and effective."
                                label="Virtual 1 on 1" />
                        </Grid>
                        <Grid item lg={4} md={6} sm={4} xs={6}>
                            <CourseTypeButton bgColor="#e9b730"
                                icon={tech_camps_icon}
                                targetLink="/techcamp-course"
                                desc="Fun tech courses to design game projects together with friends. Share and challenge."
                                label="Virtual Small Group" />
                        </Grid>
                        <Grid item lg={4} md={6} sm={4} xs={6}>
                            <CourseTypeButton bgColor="#126d77"
                                icon={courses_at_school_location_icon}
                                desc="Choose a location near you to experience tech education on your own terms. Convenient and safe."
                                label="At School Locations" />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9} style={{ margin: 'auto', paddingTop: '9%' }}>
                    <Box component={Grid} container>
                        <Grid item sm={4} xs={12}>
                            <Typography variant="h2" className="greenText"
                                style={{ fontFamily: 'Poppins', fontWeight: 900, display: 'inline', fontSize: '45px' }}>
                                2008
                            </Typography>
                            <SecondaryText>Mods or projects designed</SecondaryText>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Typography variant="h2" className="greenText"
                                style={{ fontFamily: 'Poppins', fontWeight: 900, display: 'inline', fontSize: '45px' }}>
                                81
                            </Typography>
                            <b style={{ fontSize: '28px' }}>%</b>
                            <SecondaryText>Increase in academic results</SecondaryText>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Typography variant="h2" className="greenText"
                                style={{ fontFamily: 'Poppins', fontWeight: 900, display: 'inline', fontSize: '45px' }}>
                                75
                            </Typography>
                            <b style={{ fontSize: '28px' }}>%</b>
                            <SecondaryText>Of teachers believe tech skills are a must</SecondaryText>
                        </Grid>
                    </Box>
                </Grid>
            </PageBlock>

            <PageBlock>
                <Grid item xs={12} md={8} style={{ margin: 'auto' }}>
                    <PrimaryText>
                        Spring break & summer camps
                    </PrimaryText>
                </Grid>
                <Grid item xs={12}>
                </Grid>{
                    courseListStatus.status === 'FULFILLED' &&
                    courseListStatus.data &&
                    Object.values(Data.Config.Grade).map(grade =>
                        <>
                            <Grid xs={12} item disableGutter>
                                <SecondaryText className="greenText" style={{ fontSize: '28px' }}>
                                    Grade {grade.displayName}
                                </SecondaryText>
                            </Grid>
                            <Grid xs={12} item>
                                <Box container spacing={5} component={Grid} style={{ padding: '0px 7%' }}>
                                    {
                                        courseListStatus.data
                                            .filter(course => course.grade === grade.id)
                                            .slice(0, 6)
                                            .map(item =>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <CourseBlock
                                                        to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                                        label={item.title}
                                                        grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                                        courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                                        price={item.price}
                                                        mrpPrice={item.mrpPrice}
                                                        coverPic={item.coverImage && Api.Media.getThumb(item.coverImage)}
                                                    />
                                                </Grid>
                                            )
                                    }
                                </Box>
                            </Grid>
                        </>
                    )
                }
                <Grid xs={12} item>
                    <Link to="/courses" component={PrimaryButton}>View All Courses & Plan</Link>
                </Grid>
            </PageBlock>
            <TestimonialBlock />
            <EliteTeachersBlock />
            <PageBlock>
                <Grid item xs={12} md={6}>
                    <img src={cs_video2} style={{ width: '75%' }} />
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: '5%', textAlign: 'left' }}>
                    <PrimaryText style={{ fontSize: '35px' }}>
                        <b className="greenTextBackground">Sign up</b> &nbsp;for a Free Discovery Lesson
                    </PrimaryText>
                    <br /><br />
                    <BodyText>
                        Experience a 30 Minutes Computer Course with a Live Teacher. Choose your favorite course
                        and get to see if we are the right fit for you. No strings attached.
                    </BodyText>
                </Grid>
            </PageBlock>
            <PageBlock>
                <Grid item xs={12} md={6} style={{ paddingTop: '5%' }}>
                    <PrimaryText style={{ fontSize: '35px' }}>
                        You can also go for&nbsp;
                        <b className="greenTextBackground">Computer courses at </b>
                        &nbsp;Parks & Rec Center & School Locations</PrimaryText>
                    <br /><br />
                    <BodyText style={{ textAlign: 'left' }}>
                        <TickLabels style={{ marginBottom: 15 }}
                            label="We partnered up with Schools to bring in-demand 
                            computer courses to develop tech skills for students K-8"/>
                        <TickLabels style={{ marginBottom: 15 }}
                            label="You can choose Schools and Parks & Rec Centers that fit 
                            your schedule and mobility"/>
                        <TickLabels style={{ marginBottom: 15 }}
                            label="Find a location that suits you best and register your 
                            child for as many hours as you need"/>
                    </BodyText>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={onlineClassroom} style={{ width: '80%' }} />
                </Grid>
            </PageBlock>
        </Layout>
    );
}