import staff from './../../../images/staff.PNG';
import courseImg from './../../../images/course1.JPG';
import Api from './../../../Api';
import { Box, Grid, Link, Paper, Typography, Hidden, Container, Divider } from "@material-ui/core";
import {
    PrimaryButton, PrimaryText,
    CourseBlock,SecondaryText
} from "./../../../features/Atoms"
import useFetch from 'react-fetch-hook';
import Data from './../../../Data';
export default () => {
    const { isLoading, error, data } = useFetch(Api.School.loadAtSchoolCourses());

    if (isLoading) {
        return <h1 style={{ textAlign: 'center' }}>Loading</h1>
    }
    const courseList = data.data
    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid xs={12}>
                                    <PrimaryText style={{ textAlign: 'center',}}>CyberTeck Academy’s <span style={{ backgroundColor: '#73d56e', color: 'white' }}>Top Choice</span><br/>for Schools and Parks & Rec Centers
                                    </PrimaryText>
                                    <SecondaryText style={{textAlign: 'center'}}>
                                        Tech expertise put into the service of Education
                                    </SecondaryText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '5% 10%' }}>
                            <SecondaryText style={{textAlign: 'left', fontSize: 15}}>To reach everyone’s needs, our Computer Courses will be available with a live instructor.We make sure our teachers are present at your location, both for In-Person courses and for Virtual settings.</SecondaryText>
                            <PrimaryButton>Courses we offer at your loaction</PrimaryButton>
                            <Divider />
                            <Grid container direction="row"  spacing={2} style={{ marginTop: 20 }}>
                                {
                                    courseList?.map(item => <Grid item xs={12} md={4} lg={4} style={{ textAlign: 'center' }}>
                                        <CourseBlock
                                            to={`/courses/${item.id}/${item.title.replaceAll(' ', '-')}`}
                                            label={item.title}
                                            grade={Data.Config.get(Data.Config.Grade, item.grade, 'displayName')}
                                            courseType={Data.Config.get(Data.Config.CourseType, item.courseType, 'displayName')}
                                            price={item.price}
                                            mrpPrice={item.mrpPrice}
                                            coverPic={item.coverImage ? Api.Media.getThumb(item.coverImage): null}
                                        />
                                    </Grid>)
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    )
}